export const SlashIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="181"
      height="453"
      viewBox="0 0 181 453"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.4436 452.098L0.746094 437.64L131.556 0.902344L180.254 15.36L49.4436 452.098Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
