import { useSyncExternalStore } from "react";

function subscribeToArrowClick({
  onLeftArrowClick,
  onRightArrowClick,
}: {
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}) {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowRight") {
      onRightArrowClick();
    } else if (event.key === "ArrowLeft") {
      onLeftArrowClick();
    }
  };

  window.addEventListener("keydown", handleKeyDown);

  return () => {
    window.removeEventListener("keydown", handleKeyDown);
  };
}

export function useKeyboardArrowKeyDown(callbacks: {
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
}) {
  return useSyncExternalStore(
    () => subscribeToArrowClick(callbacks), // React won't resubscribe for as long as you pass the same function
    () => undefined, // How to get the value on the client
    () => undefined, // How to get the value on the server
  );
}
