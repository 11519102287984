import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { strapiRepository } from "~/openapi/strapiRepository";
import { Services } from "~/components/homepage/services/services";
import { HeroSection } from "~/components/homepage/hero-section/hero-section";
import { InProgress } from "~/components/homepage/in-progress/in-progress";
import { References } from "~/components/homepage/references/references";
import { splitReferences } from "~/components/homepage/splitReferences";
import { components } from "~/openapi/schema";
import { Contacts } from "~/components/homepage/contacts/contacts";

export const loader = async () => {
  const { getHomepage, getReferences } = strapiRepository;
  const [{ data: homepage }, { data: allReferences }] = await Promise.all([
    getHomepage(),
    getReferences({
      [`filters[$or][0][${"isFinished" satisfies keyof components["schemas"]["Reference"]}][$eq]`]:
        "false",
      [`filters[$or][1][${"showOnHomepage" satisfies keyof components["schemas"]["Reference"]}][$eq]`]:
        "true",
    }),
  ]);

  return json({
    homepage,
    allReferences,
  });
};

export default function Index() {
  const { homepage, allReferences } = useLoaderData<typeof loader>();

  const [inProgressReferences, doneReferences] = splitReferences(
    allReferences?.data ?? [],
  );

  return (
    <div
      className={
        "container -top-navbar-height mx-auto overflow-x-hidden px-2 lg:overflow-x-visible"
      }
    >
      <HeroSection hero_photos={homepage?.data?.hero_photos} />
      {doneReferences.length > 0 && (
        <References
          references={doneReferences}
          title={homepage?.data?.references_title ?? "Reference"}
        />
      )}
      {inProgressReferences.length > 0 && (
        <InProgress
          references={inProgressReferences}
          title={homepage?.data?.in_progress_title ?? "Právě realizujeme"}
        />
      )}
      <Services homepageData={homepage?.data} />
      <Contacts
        socialLinks={homepage?.data?.social_links ?? []}
        title={homepage?.data?.contacts_title ?? "Kontakty"}
        contacts={homepage?.data?.contacts ?? []}
      />
    </div>
  );
}
