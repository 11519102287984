import { SectionTitle } from "~/components/homepage/section-title";
import { components } from "~/openapi/schema";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousel";
import { ReferenceBox } from "~/components/references/ReferenceBox";
import BlurFade from "~/components/ui/blur-fade";
import { sortReferences } from "~/components/homepage/references/sortReferences";
import { useMemo } from "react";

export const InProgress = ({
  title,
  references,
}: {
  title: string;
  references: components["schemas"]["Reference"][];
}) => {
  const sortedReferences = useMemo(
    () => sortReferences(references),
    [references],
  );

  return (
    <>
      <BlurFade inView>
        <SectionTitle title={title} />
      </BlurFade>
      <Carousel
        opts={{
          align: "start",
        }}
        className="md:mx-16"
      >
        <CarouselContent>
          {sortedReferences.map((reference, index) => {
            return (
              <CarouselItem
                key={reference.id}
                className="basis-4/5 md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
              >
                <BlurFade inViewMargin={"0px"} inView delay={0.1 * index}>
                  <ReferenceBox
                    className={
                      "h-[300px] w-full md:h-[290px] lg:h-[300px] xl:h-[330px]"
                    }
                    showYear={false}
                    mode={"compact"}
                    key={reference.id}
                    reference={reference}
                  />
                </BlurFade>
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </>
  );
};
