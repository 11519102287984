import { components } from "~/openapi/schema";

export const getCategoriesFromReferences = (
  references: components["schemas"]["Reference"][],
) => {
  const categories = references.reduce(
    (acc, currentValue) => {
      const category = currentValue.category;
      if (!category) return acc;
      const { slug, id, name } = category;
      if (!slug || !id || !name) return acc;
      if (acc.some((accItem) => accItem.id === category.id)) {
        return acc;
      }
      acc.push({
        id,
        name,
        slug,
      });
      return acc;
    },
    [] as { id: number; slug: string; name: string }[],
  );

  // sort by id
  return categories.sort((a, b) => a.id - b.id);
};
