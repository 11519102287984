import { SocialLinks } from "~/components/homepage/contacts/social-links";
import { components } from "~/openapi/schema";
import { ContactBox } from "~/components/homepage/contacts/ContactBox";
import { SectionTitle } from "~/components/homepage/section-title";
import { Button } from "~/components/ui/button";
import { Link } from "@remix-run/react";
import BlurFade from "~/components/ui/blur-fade";

export const Contacts = ({
  title,
  contacts,
  socialLinks,
}: {
  title: string;
  contacts: components["schemas"]["Employee"][];
  socialLinks: components["schemas"]["HomepageSocialLinkComponent"][];
}) => {
  return (
    <>
      <BlurFade inView>
        <SectionTitle title={title} />
      </BlurFade>
      <div className={"grid grid-cols-1 gap-4 md:grid-cols-2"}>
        {contacts.map((contact, index) => (
          <BlurFade key={contact.id} inView delay={0.1 * index}>
            <ContactBox contact={contact} />
          </BlurFade>
        ))}
      </div>
      <BlurFade inView>
        <div className={"mt-8 flex w-full justify-center"}>
          <Button asChild variant={"outline"}>
            <Link to={"/kontakty"}>Zobrazit více kontaktů</Link>
          </Button>
        </div>
      </BlurFade>
      <SocialLinks socialLinks={socialLinks} />
    </>
  );
};
