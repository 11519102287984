import { cn } from "~/lib/utils";
import { components } from "~/openapi/schema";
import { SectionTitle } from "~/components/homepage/section-title";
import { strapiRepository } from "~/openapi/strapiRepository";
import { Link } from "@remix-run/react";
import BlurFade from "~/components/ui/blur-fade";

export const Services = ({
  homepageData,
}: {
  homepageData?: components["schemas"]["Homepage"];
}) => {
  if (homepageData === undefined) return null;

  const { getImageUrl } = strapiRepository;
  const services = homepageData.services;

  return (
    <>
      <BlurFade inView>
        <SectionTitle title={homepageData.services_title} />
      </BlurFade>
      {services && (
        <BlurFade inView>
          <div className="flex w-full flex-col gap-2 md:flex-row md:gap-0">
            {services.map((service, index) => {
              if (!service.photo) return null;

              return (
                <Link
                  key={index}
                  to={`/sluzby#${service.category?.slug}`}
                  className={cn(
                    "group relative h-80 transition-all duration-300 ease-in-out",
                    {
                      "md:w-full": services.length === 1,
                      "md:w-1/2": services.length === 2,
                      "md:w-1/3 md:hover:w-1/2": services.length === 3,
                      "md:w-1/4 md:hover:w-1/2": services.length === 4,
                      "md:w-1/5 md:hover:w-1/3": services.length === 5,
                      "md:w-1/6 md:hover:w-1/3": services.length === 6,
                      "md:w-1/7 md:hover:w-1/3": services.length === 7,
                      "md:w-1/8 md:hover:w-1/4": services.length === 8,
                      "md:w-1/9 md:hover:w-1/4": services.length === 9,
                      "md:w-1/10 md:hover:w-1/4": services.length === 10,
                    },
                  )}
                >
                  <div
                    className="absolute size-full brightness-[70%] ease-in-out md:brightness-[50%] md:group-hover:bg-opacity-0 md:group-hover:brightness-95"
                    style={{
                      background: `url(${getImageUrl(service.photo, "medium")}) no-repeat center`,
                      backgroundSize: "cover",
                    }}
                  />
                  <div
                    className={
                      "relative mx-auto flex h-full w-min flex-col justify-center gap-4 text-center"
                    }
                  >
                    <h3
                      className={
                        "origin-center transform bg-foreground/0 p-2 text-center text-2xl font-semibold text-background brightness-100 transition-all duration-300 md:group-hover:bg-foreground/70 md:group-hover:text-3xl md:group-hover:backdrop-blur"
                      }
                    >
                      {service.category?.name}
                    </h3>
                  </div>
                </Link>
              );
            })}
          </div>
        </BlurFade>
      )}
    </>
  );
};
