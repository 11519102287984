import { components } from "~/openapi/schema";
import { strapiRepository } from "~/openapi/strapiRepository";
import BlurFade from "~/components/ui/blur-fade";

export const SocialLinks = ({
  socialLinks,
}: {
  socialLinks: components["schemas"]["HomepageSocialLinkComponent"][];
}) => {
  const { getImageUrl } = strapiRepository;

  return (
    <BlurFade inView>
      <div className={"my-12 flex justify-center gap-16"}>
        {socialLinks.map((socialLink, index) => (
          <a
            key={socialLink.id}
            href={socialLink.link}
            aria-label={socialLink.link}
            target="_blank"
            rel="noopener noreferrer"
            className={"transition hover:scale-105"}
          >
            {socialLink.icon && (
              <img
                src={getImageUrl(socialLink.icon)}
                alt={
                  socialLink.icon.alternativeText ??
                  socialLink.link ??
                  `socialni-sit-${index}`
                }
                className="size-16"
              />
            )}
          </a>
        ))}
      </div>
    </BlurFade>
  );
};
