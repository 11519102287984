/*
 * Splits the references into two arrays - workingOn and done
 */
import { components } from "~/openapi/schema";

export const splitReferences = (
  references: components["schemas"]["Reference"][],
) => {
  const [workingOn, done] = references.reduce(
    (acc, currentValue) => {
      if (currentValue.isFinished) {
        acc[1].push(currentValue);
      } else {
        acc[0].push(currentValue);
      }
      return acc;
    },
    [[], []] as [typeof references, typeof references],
  );

  return [workingOn, done];
};
