import { navbarHeight } from "~/../tailwind.config";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/lib/utils";
import { ChevronSharpRight } from "~/components/ui/icons/ChevronSharpRight";
import NumberFlow from "@number-flow/react";
import { SlashIcon } from "~/components/ui/icons/Slash";
import { strapiRepository } from "~/openapi/strapiRepository";
import { useIsFirstRender } from "@uidotdev/usehooks";
import { useCallback, useState } from "react";
import { components } from "~/openapi/schema";
import { useKeyboardArrowKeyDown } from "~/components/homepage/hero-section/useKeyboardArrowKeyDown";
import { useAutoslideInterval } from "~/components/homepage/hero-section/useAutoslideInterval";

const wrapperFramerProps = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};
const framerProps = {
  hidden: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1 },
};

const IMAGES_CHANGE_FREQUENCY = 6000; // in milliseconds

export const HeroSection = ({
  hero_photos,
}: {
  hero_photos: components["schemas"]["Homepage"]["hero_photos"];
}) => {
  const { getImageUrl } = strapiRepository;
  const isFirstRender = useIsFirstRender();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // pressing keyboard arrow keys should change the image
  useKeyboardArrowKeyDown({
    onLeftArrowClick: () => selectPhoto("previous"),
    onRightArrowClick: () => selectPhoto("next"),
  });

  const selectPhoto = useCallback(
    (direction: "next" | "previous") => {
      if (!hero_photos?.length) return;
      resetInterval();

      setCurrentPhotoIndex((prev) => {
        if (direction === "next") {
          return prev === hero_photos.length - 1 ? 0 : prev + 1;
        }
        return prev === 0 ? hero_photos.length - 1 : prev - 1;
      });
    },
    [hero_photos?.length],
  );

  const onNextTick = useCallback(() => selectPhoto("next"), [selectPhoto]);

  // automatically loop through photos
  const { resetInterval } = useAutoslideInterval({
    frequency: IMAGES_CHANGE_FREQUENCY,
    onNextTick,
    photosLength: hero_photos?.length ?? 0,
  });

  if (!hero_photos?.length) return null;

  return (
    <div
      className={
        "relative flex w-full items-center justify-center overflow-hidden"
      }
      style={{ height: `calc(100svh - ${navbarHeight}px)` }}
    >
      {hero_photos.map((photo, index) => (
        <AnimatePresence key={`hero-section-${photo.id}`}>
          {index === currentPhotoIndex && photo.photo && (
            <motion.img
              key={"hero-image-" + currentPhotoIndex}
              src={getImageUrl(photo.photo)}
              alt={
                photo.photo.alternativeText ??
                photo.photo.id?.toString() ??
                "vesas-fotka"
              }
              initial={isFirstRender ? {} : { opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              // @ts-ignore
              fetchpriority={"high"}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
              className={
                "absolute size-full object-cover px-4 pb-20 pt-24 md:pb-24 md:pl-52 md:pr-1 md:pt-4 lg:pl-72 lg:pr-2 lg:pt-6"
              }
            />
          )}
          {hero_photos[currentPhotoIndex].title_lines && (
            <motion.h2
              key={"title-root" + currentPhotoIndex}
              variants={wrapperFramerProps}
              initial={isFirstRender ? "show" : "hidden"}
              animate="show"
              exit="hidden"
              className={cn(
                "absolute left-6 top-8 max-w-80 md:left-10 md:top-auto lg:left-16",
              )}
            >
              {hero_photos[currentPhotoIndex].title_lines.map((line, index) => (
                <motion.span
                  variants={framerProps}
                  key={"title-root" + currentPhotoIndex + "_title-line" + index}
                  transition={{
                    type: "spring",
                    bounce: 0.1,
                  }}
                  className={cn(
                    "block w-max bg-foreground/90 px-2.5 py-3 text-2xl font-semibold leading-7 text-white sm:text-3xl md:px-3 md:py-3.5 md:text-4xl lg:px-6 lg:py-8 lg:text-5xl xl:text-6xl",
                  )}
                >
                  {line.text}
                </motion.span>
              ))}
            </motion.h2>
          )}
        </AnimatePresence>
      ))}

      <div
        className={
          "relative bottom-3 flex w-52 select-none items-center justify-between gap-2 self-end"
        }
      >
        <ChevronSharpRight
          strokeWidth={2}
          className={cn(
            "size-8 rotate-180 cursor-pointer transition-all hover:scale-110",
          )}
          onClick={() => selectPhoto("previous")}
        />
        <span
          className={
            "flex w-28 items-center justify-between text-4xl font-bold"
          }
        >
          <NumberFlow
            value={currentPhotoIndex + 1}
            className={"w-8 text-right"}
          />
          <SlashIcon className={"h-8 w-12"} />
          <span className={"w-8 text-start"}>{hero_photos.length}</span>
        </span>
        <ChevronSharpRight
          strokeWidth={2}
          className={cn("size-8 cursor-pointer transition-all hover:scale-110")}
          onClick={() => selectPhoto("next")}
        />
      </div>
    </div>
  );
};
