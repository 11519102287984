import { components } from "~/openapi/schema";
import { getCategoriesFromReferences } from "~/components/homepage/references/getCategoriesFromReferences";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { useMemo } from "react";
import { ReferenceBox } from "~/components/references/ReferenceBox";
import { SectionTitle } from "~/components/homepage/section-title";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousel";
import BlurFade from "~/components/ui/blur-fade";
import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { sortReferences } from "~/components/homepage/references/sortReferences";
import { cn } from "~/lib/utils";

const CarouselReferences = ({
  references,
  className,
}: {
  references: components["schemas"]["Reference"][];
  className?: string;
}) => {
  return (
    <Carousel
      opts={{
        align: "start",
      }}
      className={cn("md:mx-16", className)}
    >
      <CarouselContent>
        {references.map((reference, index) => {
          return (
            <CarouselItem
              key={reference.id}
              className="basis-4/5 md:basis-1/2 xl:basis-1/3"
            >
              <BlurFade delay={0.1 * index}>
                <ReferenceBox
                  className={
                    "h-[300px] w-full md:h-[320px] lg:h-[330px] xl:h-[350px]"
                  }
                  key={reference.id}
                  reference={reference}
                />
              </BlurFade>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
};

export const References = ({
  title,
  references,
}: {
  title: string;
  references: components["schemas"]["Reference"][];
}) => {
  const categories = useMemo(
    () => getCategoriesFromReferences(references),
    [references],
  );

  if (categories.length === 0) return null;

  return (
    <>
      <BlurFade inView>
        <SectionTitle title={title} />
      </BlurFade>

      <CarouselReferences
        references={sortReferences(references)}
        className={"md:hidden"}
      />

      <Tabs defaultValue={categories[0].slug} className={"hidden md:block"}>
        <BlurFade inView>
          <TabsList className={"mb-2"}>
            {categories.map((category) => (
              <TabsTrigger key={category.slug} value={category.slug}>
                {category.name}
              </TabsTrigger>
            ))}
          </TabsList>
        </BlurFade>

        {categories.map((category) => (
          <TabsContent key={category.slug} value={category.slug}>
            <BlurFade inView>
              <CarouselReferences
                references={sortReferences(
                  references.filter(
                    (reference) => reference.category?.slug === category.slug,
                  ),
                )}
              />
            </BlurFade>
          </TabsContent>
        ))}
      </Tabs>

      <BlurFade inView>
        <div className={"my-8 flex justify-center"}>
          <Button asChild variant={"outline"}>
            <Link to={"/reference"}>Zobrazit všechny</Link>
          </Button>
        </div>
      </BlurFade>
    </>
  );
};
